var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var rV,sV,kV,AV,pV,fla;$CLJS.lV=function(a,b){a=kV(a,b);return $CLJS.m(a)?a:$CLJS.il};$CLJS.mV=function(a,b){$CLJS.dj.D($CLJS.CH,$CLJS.qG,a,b)};$CLJS.nV=function(a,b,c){a=$CLJS.qy.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.oV=function(a,b){return $CLJS.nV(a,b,null)};rV=function(){$CLJS.iL(pV,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ft],null),$CLJS.Pe($CLJS.Le,$CLJS.r(qV))))};
sV=function(){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,2,[$CLJS.sk,$CLJS.C,$CLJS.Wu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ll);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(qV))};
$CLJS.tV=function(a,b){var c=$CLJS.Oi.h("mbql.clause",$CLJS.ui(a));$CLJS.iL(c,b);$CLJS.Ie($CLJS.r(qV),a)||$CLJS.dj.j(qV,$CLJS.kf,a);return null};$CLJS.uV=function(a,b,c){$CLJS.tV(a,c);$CLJS.BL.o(null,a,function(){return b});return null};$CLJS.xV=function(a,b){$CLJS.R.D(vV,wV,a,b)};$CLJS.zV=function(a,b){$CLJS.R.D(vV,yV,a,b)};
kV=function kV(a,b){return $CLJS.F.h(a,$CLJS.il)?null:$CLJS.F.h(b,$CLJS.il)?null:$CLJS.NH(a,b)?b:$CLJS.NH(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:kV.h?kV.h(d,e):kV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};AV=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.BV=new $CLJS.N(null,"short","short",1928760516);pV=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.CV=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);fla=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var qV=$CLJS.cj.g($CLJS.oi);$CLJS.EH(qV,fla,function(){rV();return $CLJS.iL(AV,sV())});rV();$CLJS.iL(AV,sV());$CLJS.iL($CLJS.DL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,pV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,AV],null)],null));
var yV=function yV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
yV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.xL],null)],null)],null)],null),b)],null)};yV.v=1;
yV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var wV=function wV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
wV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.xL],null)],null),b)};wV.v=1;wV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var vV=function vV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};vV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.wu)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.uV(b,c,a):$CLJS.tV(b,a)};vV.v=2;
vV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};