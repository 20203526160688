var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var AL,FL,yga,zga,Aga,Bga,Cga,Dga;AL=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N};$CLJS.CL=function(a){var b=function(){var c=AL(a);return c?(c=$CLJS.oe($CLJS.Zd(a)))?(c=$CLJS.ik.g($CLJS.Zd(a)),$CLJS.m(c)?c:$CLJS.CG.g($CLJS.Zd(a))):c:c}();return $CLJS.m(b)?b:$CLJS.BL.g(a)};
FL=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,2,[$CLJS.dv,"valid MBQL clause",$CLJS.Wu,function(c){c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.ll);return["invalid MBQL clause: ",$CLJS.aj.l($CLJS.G([c]))].join("")}],null),$CLJS.$f(AL)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.DL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,1,[$CLJS.dv,b],null),function(c){c=$CLJS.CL(c);
return EL(c,a)}],null)],null)};yga=new $CLJS.N("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);zga=new $CLJS.N("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Aga=new $CLJS.N("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.GL=new $CLJS.N(null,"expr","expr",745722291);
$CLJS.HL=new $CLJS.N("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.IL=new $CLJS.N("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.JL=new $CLJS.N("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.KL=new $CLJS.N("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);
$CLJS.LL=new $CLJS.N("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.ML=new $CLJS.N("lib","expression-name","lib/expression-name",-1799326590);Bga=new $CLJS.N("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.NL=new $CLJS.N("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);
$CLJS.OL=new $CLJS.N("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.PL=new $CLJS.N("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Cga=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Dga=new $CLJS.N("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);
$CLJS.DL=new $CLJS.N("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.QL=new $CLJS.N("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.RL=new $CLJS.N("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.SL=new $CLJS.N("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.BL=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Mk,$CLJS.CH],null),$CLJS.Mk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.BH(f);return $CLJS.F.h(g,$CLJS.BG)?$CLJS.Ob(f):g},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.iL(zga,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zl,$CLJS.LL],null),$CLJS.yL],null));$CLJS.BL.o(null,$CLJS.Pj,function(a){throw $CLJS.zj($CLJS.TH("{0}: Don''t know how to determine the type of {1}",$CLJS.G([Cga,$CLJS.aj.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.GL,a],null));});$CLJS.BL.o(null,$CLJS.OL,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.CL(a)});
var EL=function EL(a,b){return $CLJS.le(a)?$CLJS.Xf(function(d){return EL.h?EL.h(d,b):EL.call(null,d,b)},a):$CLJS.le(b)?$CLJS.Xf(function(d){return EL.h?EL.h(a,d):EL.call(null,a,d)},b):$CLJS.F.h(a,$CLJS.LL)?!0:$CLJS.NH(a,b)};$CLJS.iL($CLJS.JL,FL($CLJS.tl,"expression returning a boolean"));$CLJS.iL($CLJS.QL,FL($CLJS.Ll,"expression returning a string"));$CLJS.iL($CLJS.SL,FL($CLJS.dl,"expression returning an integer"));$CLJS.iL(Aga,FL($CLJS.JK,"expression returning a non-integer real number"));
$CLJS.iL($CLJS.RL,FL($CLJS.xl,"expression returning a number"));$CLJS.iL(Bga,FL($CLJS.NI,"expression returning a date"));$CLJS.iL(Dga,FL($CLJS.WK,"expression returning a time"));$CLJS.iL(yga,FL($CLJS.RJ,"expression returning a date time"));$CLJS.iL($CLJS.IL,FL($CLJS.El,"expression returning a date, time, or date time"));$CLJS.TL=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.xl,null,$CLJS.Ll,null,$CLJS.El,null],null),null);$CLJS.iL($CLJS.PL,FL($CLJS.TL,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.iL($CLJS.NL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,FL(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.xl,null,$CLJS.Ll,null,$CLJS.El,null,$CLJS.tl,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.iL($CLJS.KL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.It,FL($CLJS.il,"any type of expression")],null));
$CLJS.iL($CLJS.HL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hl,new $CLJS.k(null,1,[$CLJS.mk,1],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wt,$CLJS.Ut,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ML,$CLJS.Hl],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.em,$CLJS.Ut],null)],null)],null)],null));